import { Outlet } from 'react-router-dom';
import { ThemeProvider, CssBaseline, useMediaQuery,Box, Stack} from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch} from 'react-redux'; 
import { openDrawer } from '../store/redux/ui';
import { Navbar } from './Navbar/Navbar';
import MainDrawer from './Drawer/MainDrawer';
import uiConfig from '../config/uiConfig';

function AdminDashboard() {
    const dispatch = useDispatch(state => state.ui);
    const [theme, setTheme] = useState(responsiveFontSizes(createTheme(uiConfig.themeExtensions)));
    
    // Determine initial state of drawer menu (closed for small devices)
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(() => { dispatch(openDrawer({ drawerOpen: !smallScreen })); });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box sx={{ display: 'flex', width: '100%' }}>
                    <Navbar />
                    <MainDrawer/>
                    <Outlet/>
            </Box>
        </ThemeProvider>
    );
}

export default AdminDashboard;