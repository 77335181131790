// material-ui
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Waypoint = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
          stroke="currentColor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" className="feather feather-pilot"> 
          <path d="m19.693 10.164c0 6.2953-7.6039 11.691-7.6039 11.691s-7.6039-5.3959-7.6039-11.691a7.6039 8.0939 0 0 1 15.208 0z"/>
          <ellipse cx="12.089" cy="10.164" rx="2.5346" ry="2.698"/>
      </svg>  
    </SvgIcon>
  );
};

export default Waypoint;
