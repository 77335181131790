import { useState } from 'react';
import { Grid, Stack, Box, Autocomplete, TextField, Button, Typography } from "@mui/material";
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { useTheme } from "@emotion/react";
import MainContentArea from '../../components/Page/MainContentArea';
import MainPageArea from '../../components/Page/MainPageArea';

function Test()
{
    let testText = "Now is the time for all good men to come to the aid of the party"
    
    return (
        <MainPageArea>        
            <MainContentArea>      
                <Typography color="primary" variant="h1">{`H1 ${testText}`}</Typography>
                <Typography color="primary" variant="h2">{`H2 ${testText}`}</Typography>
                <Typography color="primary" variant="h3">{`H3 ${testText}`}</Typography>
                <Typography color="primary" variant="h4">{`H4 ${testText}`}</Typography>
                <Typography color="primary" variant="h5">{`H5 ${testText}`}</Typography>
                <Typography color="primary" variant="h6">{`H6 ${testText}`}</Typography>                        
                <Typography color="primary" variant="subtitle1">{`subtitle1 ${testText}`}</Typography>                        
                <Typography color="primary" variant="subtitle2">{`subtitle2 ${testText}`}</Typography>                        
                <Typography color="primary" variant="body1">{`body1 ${testText}`}</Typography>                        
                <Typography color="primary" variant="body2">{`body2 ${testText}`}</Typography>                                                                                        
            </MainContentArea>
        </MainPageArea>        
    )
}

export default Test;