// material-ui
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const EditAircraft = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
          stroke="currentColor" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" className="feather feather-pilot"> 
          <path d="m7.0757 9.3446c0.10364-0.031173 0.21621-0.044016 0.33771-0.038517 0.29125 0.018339 0.54678 0.13755 0.76478 0.36129s0.33414 0.486 0.35201 0.78493c0.00728 0.12472-0.00536 0.23842-0.037533 0.34662-0.014293 0.04952-0.032159 0.0972-0.051826 0.14488l1.6778 0.40164 4.0062-4.1155c0.30377-0.29527 0.6665-0.44199 1.09-0.44381 0.43421 0 0.80409 0.16139 1.115 0.47866 0.30913 0.31727 0.46458 0.6969 0.46637 1.1407 0 0.43465-0.14474 0.80879-0.43242 1.1205l-4.0133 4.1117h0.0054l0.38773 1.7294h0.0054c0.04467-0.02567 0.09113-0.04401 0.14116-0.05867 0.10364-0.03118 0.21621-0.04401 0.33771-0.03852 0.29125 0.01834 0.54678 0.13755 0.76478 0.36129s0.33414 0.486 0.35201 0.78494c0.0073 0.12472-0.0035 0.24025-0.03395 0.34845-0.04288 0.14854-0.11972 0.28059-0.23407 0.39797l-0.01787 0.01834c-0.25016 0.25309-0.49854 0.48967-0.74513 0.7134h0.0018c0.35915 1.5937 0.71832 3.1856 1.0775 4.7756l-1.6261 0.89859-1.9798-3.8899c-0.40384 0.20173-0.80408 0.35762-1.199 0.46948 0.13937-0.51717 0.35201-1.0435 0.63613-1.5772l-0.91853-1.801c-1.0042 1.0308-2.0084 2.0595-3.0126 3.0902l0.28768 1.9569-0.00538 0.0056-1.4277 0.79042-1.3848-2.4483h-0.00181l-2.3873-1.4231 0.77014-1.4671 0.00536-0.0056 1.9067 0.29527 3.0073-3.0957-1.744-0.93527c-0.52178 0.28976-1.0364 0.50616-1.5403 0.64922 0.11079-0.40531 0.26445-0.81429 0.46101-1.2305l-3.797-2.0321 0.87556-1.6707c1.5528 0.37046 3.1074 0.74275 4.6602 1.1133 0.21621-0.25309 0.44671-0.50616 0.69152-0.76109 0.00721-0.00551 0.012513-0.012842 0.01966-0.018339 0.11257-0.11738 0.24302-0.19623 0.38775-0.24025zm0.35915 1.359 1.0042 0.24025m4.5404 5.456-0.23408-1.0343m-5.684-0.89684c0.67544-0.69507 1.3508-1.3919 2.028-2.0852m-4.4493 6.8737c0.38239-0.39246 0.76478-0.78676 1.1472-1.1793m3.5165-1.3057h0.00182c0.67366-0.69691 1.349-1.3919 2.0246-2.087"/>
          <polyline transform="translate(-.24658 -6.807)" points="17 11 19 13 23 9"/>
      </svg>  
    </SvgIcon>
  );
};

export default EditAircraft;
