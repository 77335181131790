// material-ui
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Logo = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg width="128" height="128" version="1.1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
      <g strokeWidth="1.5806">
        <path className="cls-1" d="m32.972 95.362h-14.415l7.0969-13.482c0.06324-0.11065 0.20548-0.11065 0.26869 0z" fill="#f9f9f9"/>
        <path className="cls-1" d="m94.742 95.362h14.415l-7.0969-13.482c-0.0632-0.11065-0.20547-0.11065-0.2687 0z" fill="#f9f9f9"/>
        <path className="cls-1" d="m63.035 10.847-32.861 62.449h14.51l19.173-36.464 19.173 36.464h14.51l-32.861-62.449c-0.34774-0.66384-1.2961-0.66384-1.6438 0z" fill="#fafafa"/>
        <path className="cls-1" d="m76.312 73.265-12.455 8.551-12.455-8.551h-46.438c-0.2687 0-0.31612 0.37934-0.04741 0.44255l42.581 9.942 16.28 10.938s0.11065 0.0316 0.15806 0l16.28-10.938 42.581-9.942c0.2529-0.0632 0.22129-0.44255-0.0474-0.44255z" fill="#fafafa"/>
      </g>
      <g transform="matrix(.23329 0 0 .23329 66.865 118.5)" fill="#f9f9f9">
        <path transform="rotate(.8638 8.6774 -16772)" d="m8.88 0h-9.22l21.69-48.21h8.81l21.77 48.21h-9.37l-18.66-43.46h3.58zm31.61-11.16h-30.85l2.41-7.02h26.03z"/>
        <path transform="rotate(.60811 6.4048 -17331)" d="m22.93 0.48q-6.13 0-10.71-2.44-4.58-2.45-7.09-6.72t-2.51-9.71q0-5.51 2.44-9.74 2.45-4.24 6.75-6.65 4.31-2.41 9.81-2.41 5.38 0 9.58 2.34t6.61 6.61 2.41 10.06q0 0.55-0.04 1.24-0.03 0.69-0.1 1.31h-30.65v-5.72h26.11l-3.38 1.79q0.07-3.17-1.31-5.58-1.37-2.41-3.75-3.78-2.38-1.38-5.48-1.38-3.16 0-5.54 1.38-2.38 1.37-3.72 3.82-1.34 2.44-1.34 5.75v1.38q0 3.3 1.51 5.85 1.52 2.55 4.27 3.92 2.76 1.38 6.34 1.38 3.1 0 5.58-0.96 2.48-0.97 4.41-3.03l4.61 5.3q-2.48 2.89-6.23 4.44-3.76 1.55-8.58 1.55z"/>
        <path transform="rotate(.39819 4.9241 -17772)" d="m14.26 0h-8.61v-36.78h8.19v10.13l-0.96-2.96q1.65-3.72 5.2-5.65 3.54-1.93 8.85-1.93v8.2q-0.55-0.14-1.04-0.18-0.48-0.03-0.96-0.03-4.89 0-7.78 2.86t-2.89 8.5z"/>
        <path transform="rotate(.18512 6.8008 -24040)" d="m22.18 0.48q-5.65 0-10.06-2.44-4.41-2.45-6.95-6.72-2.55-4.27-2.55-9.71 0-5.51 2.55-9.74 2.54-4.24 6.95-6.65t10.06-2.41q5.71 0 10.15 2.41 4.45 2.41 6.96 6.61t2.51 9.78q0 5.44-2.51 9.71t-6.96 6.72q-4.44 2.44-10.15 2.44zm0-7.37q3.16 0 5.64-1.37 2.48-1.38 3.89-4 1.42-2.62 1.42-6.13 0-3.58-1.42-6.16-1.41-2.58-3.89-3.96t-5.58-1.38q-3.16 0-5.61 1.38-2.44 1.38-3.89 3.96t-1.45 6.16q0 3.51 1.45 6.13t3.89 4q2.45 1.37 5.55 1.37z"/>
        <path transform="rotate(-.057961 6.5253 15418)" d="m21.42 0.69q-5.65 0-10.81-1.62-5.17-1.62-8.2-4.17l3.1-6.95q2.89 2.27 7.16 3.79 4.27 1.51 8.75 1.51 3.79 0 6.13-0.83 2.34-0.82 3.44-2.23 1.1-1.42 1.1-3.21 0-2.2-1.58-3.54-1.59-1.35-4.1-2.14t-5.58-1.48q-3.06-0.69-6.13-1.62-3.06-0.93-5.57-2.41-2.52-1.48-4.1-3.96-1.59-2.48-1.59-6.33 0-3.93 2.1-7.2t6.41-5.23q4.3-1.97 10.91-1.97 4.34 0 8.61 1.11 4.27 1.1 7.44 3.16l-2.82 6.96q-3.24-1.93-6.68-2.86-3.45-0.93-6.61-0.93-3.72 0-6.03 0.9-2.31 0.89-3.38 2.34-1.06 1.44-1.06 3.3 0 2.21 1.55 3.55t4.06 2.1 5.61 1.48 6.17 1.62q3.06 0.9 5.58 2.34 2.51 1.45 4.06 3.93t1.55 6.26q0 3.86-2.1 7.13t-6.44 5.24q-4.34 1.96-10.95 1.96z"/>
        <path transform="rotate(-.30597 7.2828 -8498.7)" d="m21.21 0.48q-4.68 0-8.26-1.75-3.58-1.76-5.58-5.38-2-3.61-2-9.12v-21.01h8.61v19.84q0 4.96 2.24 7.4 2.24 2.45 6.37 2.45 3.03 0 5.27-1.24t3.51-3.72 1.27-6.13v-18.6h8.61v36.78h-8.19v-9.92l1.44 3.03q-1.86 3.58-5.44 5.48-3.58 1.89-7.85 1.89z"/>
        <path transform="rotate(-.5074 .7059 -12406)" d="m14.26 0h-8.61v-36.78h8.61zm-4.27-42.84q-2.41 0-3.96-1.51-1.55-1.52-1.55-3.65 0-2.21 1.55-3.69t3.96-1.48 3.96 1.42q1.55 1.41 1.55 3.54 0 2.27-1.52 3.82-1.51 1.55-3.99 1.55z"/>
        <path transform="rotate(-.67114 2.9613 -12573)" d="m19.49 0.48q-6.06 0-9.37-3.13-3.3-3.13-3.3-9.26v-32.99h8.61v32.78q0 2.62 1.34 4.06 1.34 1.45 3.75 1.45 2.9 0 4.82-1.52l2.41 6.13q-1.51 1.24-3.71 1.86-2.21 0.62-4.55 0.62zm5.78-30.09h-24.51v-6.89h24.51z"/>
        <path transform="rotate(-.88052 6.4048 -12548)" d="m22.93 0.48q-6.13 0-10.71-2.44-4.58-2.45-7.09-6.72t-2.51-9.71q0-5.51 2.44-9.74 2.45-4.24 6.75-6.65 4.31-2.41 9.81-2.41 5.38 0 9.58 2.34t6.61 6.61 2.41 10.06q0 0.55-0.04 1.24-0.03 0.69-0.1 1.31h-30.65v-5.72h26.11l-3.38 1.79q0.07-3.17-1.31-5.58-1.37-2.41-3.75-3.78-2.38-1.38-5.48-1.38-3.16 0-5.54 1.38-2.38 1.37-3.72 3.82-1.34 2.44-1.34 5.75v1.38q0 3.3 1.51 5.85 1.52 2.55 4.27 3.92 2.76 1.38 6.34 1.38 3.1 0 5.58-0.96 2.48-0.97 4.41-3.03l4.61 5.3q-2.48 2.89-6.23 4.44-3.76 1.55-8.58 1.55z"/>
      </g>
      </svg>    
    </SvgIcon>
  );
};

export default Logo;
