import { useTheme } from '@emotion/react';
import { IconButton, Button, Tooltip } from '@mui/material';
import uiConfig from '../../config/uiConfig';

function MainActionIcon(props) {
  const theme = useTheme();  
  return (
    <Tooltip title={props.tooltip} placement="top" enterDelay={uiConfig.tooltipEnterDelay} leaveDelay={uiConfig.tooltipExitDelay}>
      <Button variant="outlined" onClick={props.onClick}>{props.icon}</Button>
    </Tooltip>
)
}

export default MainActionIcon;