// material-ui
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Clouds = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="feathericon">
          <path d="m14.85 13.807h-0.9875a6.2698 6.2698 0 1 0-6.066 7.8373h7.0535a3.9186 3.9186 0 0 0 0-7.8373z"/>
          <path d="m20.655 16.936c1.0431-0.74388 1.5647-1.9821 1.5647-3.2203 0-1.9593-1.306-3.9184-3.918-3.918h-0.9875c-1.3032-5.0474-7.8382-6.3922-11.029-2.2701"/>        
        </svg>
    </SvgIcon>
  );
};

export default Clouds;
