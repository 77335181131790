// material-ui
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Home = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
          stroke="currentColor" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" className="feathericon"> 
          <path d="m5.0571 9.5071 7.4446-5.7902 7.4446 5.7902v9.0989a1.6543 1.6543 0 0 1-1.6543 1.6543h-11.58a1.6543 1.6543 0 0 1-1.6543-1.6543z"/>
          <polyline transform="matrix(.82717 0 0 .82717 2.5756 2.0626)" points="9 22 9 12 15 12 15 22"/>
      </svg>  
    </SvgIcon>
  );
};
export default Home;
