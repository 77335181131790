// material-ui
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const EditTakeoff = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
          stroke="currentColor" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" className="feathericon"> 
          <path d="m5.0198 15.907c-0.62175 0.26522-1.2435 0.53225-1.8653 0.79746 1.4658 1.2716 4.2689 1.1844 8.4092-0.26522 0.46197-0.16167 0.94127-0.3397 1.438-0.53589l5.9934-2.5431c0.01565-9e-3 0.03126-0.01817 0.04515-0.02542 0.04515-0.0218 0.08857-0.0436 0.132-0.0654 0.37687-0.19074 0.69122-0.38148 0.94482-0.57221 0.30045-0.23615 0.51754-0.47593 0.65301-0.7139 0.04515-0.07992 0.08162-0.15804 0.10768-0.23797 0.02605-0.06903 0.04689-0.13442 0.06253-0.19619 0.01911-0.07629 0.0278-0.15078 0.02952-0.22162 0-0.14169-0.033-0.26885-0.10074-0.38329-0.07296-0.11989-0.18409-0.22707-0.33345-0.31972-0.0139-0.0072-0.0278-0.01453-0.04341-0.0218-0.099-0.0545-0.20147-0.10173-0.30914-0.14715-0.45503-0.18347-0.95519-0.24342-1.5005-0.18165-0.34735 0.04905-0.69642 0.14895-1.0524 0.29792l-3.5655 1.4914-8.2808-2.2198-0.88228 1.0573 4.0205 3.3479 1.4033 1.168 2.777-1.188m-9.9466 2.476c-0.25704 0.11081-0.51407 0.2198-0.76937 0.33061m6.5406-2.7884-2.1848 0.93007-4.4738-1.2007-0.95345 1.1371 0.76937 0.63761m-0.45677 7.228h21.007"/>
          <polyline transform="translate(-.42489 -5.8369)" points="17 11 19 13 23 9"/>    
      </svg>  
    </SvgIcon>
  );
};

export default EditTakeoff;
