import { useMediaQuery, AppBar, Toolbar, IconButton, Box, Stack, Avatar, Badge, Typography } from '@mui/material';
import Logo from '../../components/icons/Logo';
import { useSelector, useDispatch} from 'react-redux'; 
import { openDrawer } from '../../store/redux/ui';
import { useTheme } from '@emotion/react';
import { Menu, SettingsOutlined, NotificationsNoneOutlined } from '@mui/icons-material';
//import Bell from '../../components/icons/Bell';
import uiConfig from '../../config/uiConfig';


export const Navbar = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Drawer toggle
  const dispatch          = useDispatch();  
  const {drawerOpen}      = useSelector( state => state.uiSettings);       
  const handleDrawerToggle = () => { dispatch(openDrawer({drawerOpen:!drawerOpen})); };    

  return (
    <AppBar position="fixed" sx={{ bgcolor: theme.toolbar, zIndex: (theme) => theme.zIndex.drawer + 1, minHeight:uiConfig.toolBarHeight, height:uiConfig.toolBarHeight}}>
    <Toolbar sx={{minHeight:uiConfig.toolBarHeight, height:uiConfig.toolBarHeight}} >
      <Box sx={{flexGrow:1}}>
        <Stack alignItems="left" direction='row' spacing={(smallScreen ? 0 : 2)}>
          <IconButton sx={{pl:0}}><Logo fontSize= {smallScreen ? "medium" : "large"}/></IconButton>                    
          <IconButton size="medium" edge="start" color="inherit" onClick={handleDrawerToggle} aria-label="open drawer">
              <Menu height="100%"/>
          </IconButton>                          
        </Stack>        
      </Box>      
      <Stack alignItems="right" direction='row' spacing={2}>
        <IconButton>
          <Badge variant='dot' color="warning">            
            <NotificationsNoneOutlined sx={{fontSize:uiConfig.toolBarElementSize, color:theme.toolbarFont}}/>
          </Badge>            
        </IconButton>
        <IconButton>
            <SettingsOutlined sx={{fontSize:uiConfig.toolBarElementSize, color:theme.toolbarFont}}/>            
        </IconButton>                
        <IconButton><Avatar sx={{height:uiConfig.toolBarElementSize, width:uiConfig.toolBarElementSize}}><Typography variant="subtitle1">AD</Typography></Avatar></IconButton>        
      </Stack>
    </Toolbar>
  </AppBar>    
  )
}
