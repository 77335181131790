
import { Outlet } from "react-router-dom";
import MainPageArea from "../../components/Page/MainPageArea";
import MainContentArea from "../../components/Page/MainContentArea";

function Tenants()
{
    return(
        <MainPageArea>
            <MainContentArea>
                <Outlet/>
            </MainContentArea>
        </MainPageArea>        
    )    
}

export default Tenants; 
