import { Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import MainPageArea from '../../components/Page/MainPageArea';
import MainPageHeader from "../../components/Page/MainPageHeader";
import MainContentCard from '../../components/Page/MainContentCard';
import MainActionIcon from '../../components/Page/MainActionIcon';
import DataTable from '../../components/Common/DataTable';
import Clouds from '../../components/icons/Clouds';
import AddCloud from '../../components/icons/Cloud-add';
import EditCloud from '../../components/icons/Cloud-edit';
import DeleteCloud from '../../components/icons/Cloud-delete';

import uiConfig from "../../config/uiConfig";

function BrowseTenants()
{

    const navigate = useNavigate();

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'tenantName', headerName: 'NAME', type:'string', flex: 1 }, 
      ];
      
     const rows = [];    

    function addNewTenant() { navigate("/tenant/add") };
    function editTenant() { console.log("edit tenant"); };
    function deleteTenant() { console.log("delete tenant"); };  

    return(               
        <Stack direction="column" flexGrow={1} spacing={1}>               
            <MainPageHeader title="TENANTS">
                <MainActionIcon icon={<Clouds sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Browse tenants"/>    
                <MainActionIcon icon={<AddCloud sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Add tenant" onClick={addNewTenant}/>   
                <MainActionIcon icon={<EditCloud sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Edit tenant" onClick={editTenant}/>                       
                <MainActionIcon icon={<DeleteCloud sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Delete tenant" onClick={deleteTenant}/>                                                             
            </MainPageHeader>
            <MainContentCard>
                    <DataTable rows={rows} columns={columns}/>
            </MainContentCard>                                
        </Stack>
    )    
}

export default BrowseTenants; 
