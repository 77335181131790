// material-ui
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Aircraft = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
          stroke="currentColor" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" className="feather feather-pilot"> 
          <path d="m10.442 5.261c0.13369-0.039179 0.2789-0.05532 0.43563-0.048409 0.3757 0.023051 0.70531 0.17287 0.98651 0.45407 0.2812 0.2812 0.43102 0.61081 0.45407 0.98651 0.0093 0.15674-0.0069 0.29964-0.04841 0.43563-0.01843 0.06223-0.04148 0.12216-0.06684 0.18209l2.1643 0.50478 5.1677-5.1723c0.39184-0.37109 0.85974-0.55549 1.406-0.55779 0.5601 0 1.0372 0.20283 1.4383 0.60159 0.39875 0.39875 0.59928 0.87587 0.60159 1.4337 0 0.54627-0.1867 1.0165-0.55779 1.4083l-5.1769 5.1677h0.0069l0.50017 2.1736h0.0069c0.05763-0.03227 0.11755-0.05532 0.18209-0.07375 0.13369-0.03918 0.2789-0.05532 0.43563-0.04841 0.3757 0.02305 0.70531 0.17287 0.98651 0.45407 0.2812 0.2812 0.43102 0.61081 0.45407 0.98651 0.0093 0.15674-0.0046 0.30195-0.0438 0.43794-0.05532 0.1867-0.15443 0.35265-0.30194 0.50017l-0.02305 0.02305c-0.32269 0.31808-0.64308 0.61542-0.96116 0.89662h0.0023c0.46329 2.003 0.92658 4.0037 1.3899 6.002l-2.0975 1.1294-2.5539-4.8888c-0.52092 0.25354-1.0372 0.44946-1.5466 0.59006 0.17978-0.64999 0.45407-1.3115 0.82056-1.9822l-1.1847-2.2634c-1.2954 1.2954-2.5907 2.5884-3.8861 3.8838l0.37109 2.4594-0.00693 0.0069-1.8416 0.99342-1.7863-3.0771h-0.00234l-3.0794-1.7886 0.99343-1.8439 0.00691-0.0069 2.4594 0.37109 3.8792-3.8907-2.2496-1.1755c-0.67305 0.36418-1.3369 0.63616-1.9869 0.81594 0.14291-0.50939 0.34113-1.0234 0.59467-1.5466l-4.8979-2.5539 1.1294-2.0998c2.003 0.4656 4.0083 0.9335 6.0113 1.3991 0.2789-0.31808 0.57623-0.63616 0.89201-0.95655 0.0093-0.00691 0.01614-0.016141 0.02536-0.023051 0.14521-0.14752 0.31347-0.24663 0.50017-0.30195zm0.46329 1.708 1.2954 0.30195m5.8568 6.8572-0.30195-1.3m-7.332-1.1271c0.87126-0.87357 1.7425-1.7494 2.6161-2.6207m-5.7393 8.6389c0.49326-0.49326 0.98651-0.98882 1.4798-1.4821m4.5361-1.6411h0.0023c0.86896-0.87588 1.7402-1.7494 2.6115-2.623"/>
      </svg>  
    </SvgIcon>
  );
};

export default Aircraft;
