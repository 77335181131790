// material-ui
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Takeoff = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
          stroke="currentColor" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" className="feathericon"> 
          <path d="m4.9396 11.638c-0.71979 0.29112-1.4396 0.58423-2.1594 0.87534 1.6969 1.3958 4.942 1.3001 9.7352-0.29112 0.53482-0.17746 1.0897-0.37287 1.6648-0.58822l6.9385-2.7915c0.0181-0.00996 0.03619-0.019941 0.05227-0.027914 0.05227-0.023932 0.10254-0.047855 0.15281-0.071778 0.4363-0.20937 0.80021-0.41873 1.0938-0.62809 0.34783-0.25921 0.59915-0.52241 0.75598-0.78362 0.05227-0.087736 0.09449-0.17347 0.12466-0.26121 0.03016-0.07577 0.05429-0.14755 0.07238-0.21535 0.02212-0.083744 0.03217-0.1655 0.03418-0.24326 0-0.15553-0.03821-0.2951-0.11662-0.42072-0.08445-0.1316-0.21312-0.24924-0.38603-0.35094-0.01609-0.00796-0.03217-0.015948-0.05026-0.023923-0.1146-0.059821-0.23323-0.11166-0.35788-0.16151-0.52677-0.20139-1.1058-0.26719-1.7371-0.19939-0.40212 0.053839-0.80624 0.1635-1.2184 0.32701l-4.1277 1.637-9.5865-2.4366-1.0214 1.1605 4.6545 3.6749 1.6246 1.2821 3.2149-1.304m1.0696 3.0368 3.2008 1.3459-1.3571 3.1744m-14.428-4.8393c-0.29757 0.12163-0.59513 0.24127-0.89069 0.3629m7.5719-3.0607-2.5293 1.0209-5.1793-1.318-1.1038 1.2482 0.89069 0.69988m-0.52879 7.9339h22.153m-13.863-1.4955 9.2648-3.7267"/>
      </svg>  
    </SvgIcon>
  );
};

export default Takeoff;
