// ==============================|| THEME CONFIG  ||============================== //

const uiConfig = {
  drawerWidth: 250,
  toolBarHeight: {xs:36, sm:36, md:56, lg:56, xl:56},
  toolBarElementSize: {xs:24, sm:24, md:32, lg:32, xl:32},  
  drawerTitleFontSize : {xs:12, sm:12, md:12, lg:14, xl:14},  
  drawerFirstLevelFontSize : {xs:12, sm:12, md:12, lg:14, xl:14},  
  drawerScndLevelFontSize : {xs:10, sm:10, md:12, lg:12, xl:12},  
  pageActionIconSize: {xs:26, sm:26, md:28, lg:30, xl:30},  
  pageContentPadding: {xs:"14px", sm:"14px", md:"16px", lg:"24px", xl:"24px"},  
  cardContentPadding: {xs:"8px", sm:"8px", md:"16px", lg:"24px", xl:"24px"},    
  tooltipEnterDelay: 750,
  tooltipExitDelay: 100,
  themeExtensions: {  typography: { fontSize: 14,
                                    h1: { fontSize: "4.25rem"}, 
                                    h2: { fontSize: "3.5rem"}, 
                                    h3: { fontSize: "2.5rem"}, 
                                    h4: { fontSize: "1.75rem"}, 
                                    h5: { fontSize: "1.25rem"}, 
                                    h6: { fontSize: "1.0rem"},   
                                    subtitle1: { fontSize: "1.0rem"},   
                                    subtitle2: { fontSize: "0.875rem"},                                                                           
                                    body1: { fontSize: "1.0rem"},   
                                    body2: { fontSize: "0.875rem"},                                       
                                  },
                      toolbar: '#4e4f4a',
                      toolbarFont: '#ffffff',
                      drawer:  '#4e4f4a',
                      tableHeader: '#dadada',
                      tableHeaderText: 'primary.text',
                      pageBg: '#e0e0e0'},
};

export default uiConfig;
