export const msalConfig = {
    auth: {
      //redirectUri: "https://aerosuiteservice.com",      
      clientId: "8e7f11f9-fb8c-48c4-a915-1a1ba037938a",
      //redirectUri: "http://localhost:3000/home",      
      //clientId: "78a9f7b8-a8eb-4679-ab79-3d84430c3e70",
      authority: "https://aerosuiteservice.b2clogin.com/aerosuiteservice.onmicrosoft.com/B2C_1_login/v2.0",
      knownAuthorities: ["aerosuiteservice.b2clogin.com"], 
    },
    cache: {
      cacheLocation: "localStorage", 
      storeAuthStateInCookie: false,   
    }
  };
  
export const loginRequest = {
   scopes: ['openid', 'offline_access', 'email', 'profile', 'https://aerosuiteservice.onmicrosoft.com/restapi/appuser']
  };
  