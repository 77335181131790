import { useTheme, CssBaseline,Box, Drawer, Toolbar, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Divider} from '@mui/material';
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Home from '../../components/icons/Home';
import Takeoff from '../../components/icons/Takeoff';
import Runway from '../../components/icons/Runway';
import Waypoint from '../../components/icons/Waypoint';
import Aircraft from "../../components/icons/Aircraft";
import Alert from '../../components/icons/Alert';
import Pilot from '../../components/icons/Pilot';
import BarChart from '../../components/icons/BarChart';
import Users from '../../components/icons/Users';
import Briefcase from '../../components/icons/Briefcase';
import CreditCard from '../../components/icons/CreditCard';
import Book from '../../components/icons/Book';
import Headphones from '../../components/icons/Headphones';
import Cloud from '../../components/icons/Cloud';
import uiConfig from "../../config/uiConfig";

const ListBlockTitle            = (props) => (<ListItemText primaryTypographyProps={{pl:2, sx:{fontSize:uiConfig.drawerTitleFontSize}}} {...props}>{props.children}</ListItemText>);
const FirstLevelListItemButton  = (props) => (<ListItemButton sx={{pb:0}} {...props}>{props.children}</ListItemButton>);
const FirstLevelListItemText    = (props) => (<ListItemText primaryTypographyProps={{ variant:"h6"}} {...props}>{props.children}</ListItemText>);
const SecondLevelListItemButton = (props) => (<ListItemButton sx={{py:0, pl:9}} {...props}>{props.children}</ListItemButton>);
const FirstLevelListItemIcon    = (props) => (<ListItemIcon sx={{mr:"0px"}} {...props}>{props.children}</ListItemIcon>);
const SecondLevelListItemIcon   = (props) => (<ListItemIcon sx={{mr:"0px"}} {...props}>{props.children}</ListItemIcon>);
const SecondLevelListItemText   = (props) => (<ListItemText primaryTypographyProps={{variant:"subtitle1"}} {...props}>{props.children}</ListItemText>);

function MainDrawer( ) {
    const theme = useTheme();
    const navigate = useNavigate();
    const drawerOpen = useSelector(state => state.uiSettings.drawerOpen);
    const [itemOpen, setItemOpen] = useState({home:true, flights:true, aircrafts:true, crew:true, reports: false, admin: false, help: false, sysadmin: false, test:false});

    const handleCollapseClick = (clicked) => { setItemOpen(prevState => { let newState = {...prevState}; newState[clicked] = !prevState[clicked]; return newState})};

    return (
        <Box sx={{ display: 'flex'}} aria-label="Aero Suite Menu">
            <CssBaseline />            
            <Drawer variant="persistent" anchor="left" open={drawerOpen} sx={{width:uiConfig.drawerWidth, flexShrink:0, '& .MuiDrawer-paper': {width: uiConfig.drawerWidth, boxSizing: 'border-box',}}}>
                <Toolbar sx={{minHeight:uiConfig.toolBarHeight, height:uiConfig.toolBarHeight}}/>        
                <Box>
                    <ListItemButton onClick={() => navigate("/")}>                                
                        <FirstLevelListItemIcon> <Home/></FirstLevelListItemIcon>                                
                        <FirstLevelListItemText primary="HOME" aria-label="Dashboard home"/>                                                      
                    </ListItemButton>                     
                </Box>                    
                <Box>
                    <Divider/>                                                  
                    <List>
                        <FirstLevelListItemButton onClick={() => handleCollapseClick("flights")}>
                            <FirstLevelListItemText primary="FLIGHTS" aria-label="Flights"/>
                            {itemOpen.flights ? <ExpandLess /> : <ExpandMore />}
                        </FirstLevelListItemButton>                    
                        <Collapse in={itemOpen.flights} timeout="auto" unmountOnExit>
                            < List component="div" disablePadding>  
                                <FirstLevelListItemButton onClick={() => navigate("flight")}>
                                    <FirstLevelListItemIcon><Takeoff/></FirstLevelListItemIcon>                                    
                                    <FirstLevelListItemText primary="Flights"/>
                                </FirstLevelListItemButton>                                                                                                   
                                <FirstLevelListItemButton>
                                    <FirstLevelListItemIcon> <Runway/></FirstLevelListItemIcon>                                                                       
                                    <FirstLevelListItemText primary="Aerodromes"/>
                                </FirstLevelListItemButton>                   
                                <FirstLevelListItemButton>
                                    <FirstLevelListItemIcon> <Waypoint/></FirstLevelListItemIcon>                                                                       
                                    <FirstLevelListItemText primary="Waypoints"/>
                                </FirstLevelListItemButton>                                                                  
                                <FirstLevelListItemButton>
                                    <FirstLevelListItemIcon> <Alert/></FirstLevelListItemIcon>                                      
                                    <FirstLevelListItemText primary="Incidents"/>
                                </FirstLevelListItemButton>                                
                            </List>                        
                        </Collapse>                    
                    </List>                     
                </Box>
                <Box>
                    <Divider/>      
                    <List>   
                        <FirstLevelListItemButton onClick={() => handleCollapseClick("aircrafts")}>
                            <FirstLevelListItemText primary="AIRCRAFTS" aria-label="Aircrafts"/>
                            {itemOpen.aircrafts ? <ExpandLess /> : <ExpandMore />}
                        </FirstLevelListItemButton >                    
                        <Collapse in={itemOpen.aircrafts} timeout="auto" unmountOnExit>
                            < List component="div" disablePadding>
                                <FirstLevelListItemButton onClick={() => navigate("aircraft")}>                                
                                    <FirstLevelListItemIcon> <Aircraft/></FirstLevelListItemIcon>                                
                                    <FirstLevelListItemText primary="Aircrafts" aria-label="Browse aircrafts"/>                                                      
                                </FirstLevelListItemButton>                                    
                            </List>                        
                        </Collapse>                                                 
                    </List>
                </Box>                   
                <Box>
                    <Divider/>      
                    <List>
                        <FirstLevelListItemButton onClick={() => handleCollapseClick("crew")}>
                            <FirstLevelListItemText primary="CREW" aria-label="Aircrew"/>
                            {itemOpen.crew ? <ExpandLess /> : <ExpandMore />}
                        </FirstLevelListItemButton >                    
                        <Collapse in={itemOpen.crew} timeout="auto" unmountOnExit>                        
                            <FirstLevelListItemButton onClick={() => handleCollapseClick("pilots")}>
                                <FirstLevelListItemIcon> <Pilot/></FirstLevelListItemIcon>
                                <FirstLevelListItemText primary="Pilots" aria-label="Pilots"/>
                            </FirstLevelListItemButton >                                                                       
                        </Collapse>
                    </List>
                </Box>       
                <Box>
                    <Divider/>      
                    <List>
                        <FirstLevelListItemButton onClick={() => handleCollapseClick("reports")}>
                            <FirstLevelListItemText primary="REPORTS" aria-label="Aero Suite REports"/>
                            {itemOpen.reports ? <ExpandLess /> : <ExpandMore />}
                        </FirstLevelListItemButton>     
                        <Collapse in={itemOpen.reports} timeout="auto" unmountOnExit>                            
                            <FirstLevelListItemButton>
                                <FirstLevelListItemIcon> <BarChart/></FirstLevelListItemIcon>
                                <FirstLevelListItemText primary="Flight Reports" aria-label="Flight Reports"/>                                   
                            </FirstLevelListItemButton >                                                         
                        </Collapse>    
                    </List>
                </Box>                                  
                <Box>
                    <Divider/>      
                    <List>
                        <FirstLevelListItemButton onClick={() => handleCollapseClick("admin")}>
                            <FirstLevelListItemText primary="ACCOUNT ADMIN" aria-label="Company Administration"/>
                            {itemOpen.admin ? <ExpandLess /> : <ExpandMore />}
                        </FirstLevelListItemButton>     
                        <Collapse in={itemOpen.admin} timeout="auto" unmountOnExit>                        
                            <FirstLevelListItemButton>
                                <FirstLevelListItemIcon> <Users/></FirstLevelListItemIcon>
                                <FirstLevelListItemText primary="Users" aria-label="Users administration"/>                            
                            </FirstLevelListItemButton >     
                            <FirstLevelListItemButton>
                                <FirstLevelListItemIcon><Briefcase/></FirstLevelListItemIcon>
                                <FirstLevelListItemText primary="Customers" aria-label="Company customers"/>                            
                            </FirstLevelListItemButton >                                 
                            <FirstLevelListItemButton onClick={() => handleCollapseClick("subscriptions")}>
                                <FirstLevelListItemIcon> <CreditCard/></FirstLevelListItemIcon>
                                <FirstLevelListItemText primary="Subscriptions" aria-label="Aerosuite subscriptions"/>
                                {itemOpen.subscriptions ? <ExpandLess /> : <ExpandMore />}
                            </FirstLevelListItemButton>                    
                            <Collapse in={itemOpen.subscriptions} timeout="auto" unmountOnExit>
                                < List component="div" disablePadding>
                                    <SecondLevelListItemButton>
                                        <SecondLevelListItemText primary="Active Subscriptions"/>
                                    </SecondLevelListItemButton>                                
                                    <SecondLevelListItemButton>
                                        <SecondLevelListItemText primary="Billing"/>
                                    </SecondLevelListItemButton>                       
                                </List>                        
                            </Collapse>     
                        </Collapse>                         
                    </List>
                </Box>    
                <Box>
                    <Divider/>      
                    <List>
                        <FirstLevelListItemButton onClick={() => handleCollapseClick("help")}>
                            <FirstLevelListItemText primary="HELP" aria-label="Help"/>
                            {itemOpen.help ? <ExpandLess /> : <ExpandMore />}
                        </FirstLevelListItemButton>     
                        <Collapse in={itemOpen.help} timeout="auto" unmountOnExit>
                            <FirstLevelListItemButton>
                                <FirstLevelListItemIcon> <Book/></FirstLevelListItemIcon>
                                <FirstLevelListItemText primary="Knowledge Base" aria-label="Knowledge Base"/>                                   
                            </FirstLevelListItemButton >    
                            <FirstLevelListItemButton >
                                <FirstLevelListItemIcon> <Headphones/></FirstLevelListItemIcon>
                                <FirstLevelListItemText primary="Support Tickets" aria-label="Support Tickets"/>                              
                            </FirstLevelListItemButton >                                                         
                        </Collapse>
                    </List>
                </Box>                      
                <Box>
                    <Divider/>      
                    <List>
                        <FirstLevelListItemButton onClick={() => handleCollapseClick("sysadmin")}>
                            <FirstLevelListItemText primary="SYSTEM ADMIN" aria-label="System Administration"/>
                            {itemOpen.sysadmin ? <ExpandLess /> : <ExpandMore />}
                        </FirstLevelListItemButton>    
                        <Collapse in={itemOpen.sysadmin} timeout="auto" unmountOnExit>           
                            <FirstLevelListItemButton onClick={() => navigate("tenant/browse")}>
                                <FirstLevelListItemIcon> <Cloud/></FirstLevelListItemIcon>
                                <FirstLevelListItemText primary="Tenants" aria-label="Tenants"/>
                            </FirstLevelListItemButton>                    
                        </Collapse>
                    </List>
                </Box>                    


                <Box>
                    <Divider/>      
                    <List>
                        <FirstLevelListItemButton onClick={() => handleCollapseClick("test")}>
                            <FirstLevelListItemText primary="TEST PAGES" aria-label="test"/>
                            {itemOpen.test ? <ExpandLess /> : <ExpandMore />}
                        </FirstLevelListItemButton>    
                        <Collapse in={itemOpen.test} timeout="auto" unmountOnExit>           
                        <FirstLevelListItemButton onClick={() => navigate("test")}>
                                <FirstLevelListItemIcon> <Book/></FirstLevelListItemIcon>
                                <FirstLevelListItemText primary="Fonts" aria-label="fonts"/>                                   
                            </FirstLevelListItemButton >                  
                        </Collapse>
                    </List>
                </Box>       
            </Drawer>                     
        </Box>
    );
}

export default MainDrawer;