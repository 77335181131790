import { useTheme } from '@emotion/react';
import { Box, Paper, Typography} from '@mui/material';
import uiConfig from '../../config/uiConfig';

function ContentCard (props) {
  const theme = useTheme();
  return (
      <Box id="box" width={props.width !== undefined ? props.width : "100%"} flexGrow={1}>
        <Paper id="paper" elevation={4} sx={{width:"100%", padding: uiConfig.cardContentPadding }}>
          {props.title !== undefined ? <Typography variant="subtitle2" color="primary" mb={1} >{props.title}</Typography> : ""}          
          {props.children}
        </Paper>
      </Box>        
  )
}

export default ContentCard;
