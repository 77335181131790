import { Stack } from '@mui/material';
import MainPageArea from "../../components/Page/MainPageArea";
import MainPageHeader from "../../components/Page/MainPageHeader";
import MainContentArea from "../../components/Page/MainContentArea";
import MainActionIcon from "../../components/Page/MainActionIcon";
import AircraftsIcon from "../../components/icons/Aircrafts";
import AddAircraft from "../../components/icons/Aircraft-add";
import EditAircraft from "../../components/icons/Aircraft-edit";
import DeleteAircraft from "../../components/icons/Aircraft-delete";
import uiConfig from "../../config/uiConfig";

function Aircrafts()
{
    return(
        <MainPageArea>
            <MainContentArea>
                <Stack direction="column" spacing={1}>               
                <MainPageHeader title="AIRCRAFTS">
                    <MainActionIcon icon={<AircraftsIcon sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Browse aircrafts"/> 
                    <MainActionIcon icon={<AddAircraft sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Add aircraft"/> 
                    <MainActionIcon icon={<EditAircraft sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Edit aircraft"/>                                 
                    <MainActionIcon icon={<DeleteAircraft sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Delete aircraft"/>                 
                </MainPageHeader>
                </Stack>
            </MainContentArea>
        </MainPageArea>        
    )    
}

export default Aircrafts; 
