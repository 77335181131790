import { Toolbar, Box, styled } from '@mui/material';
import { useSelector } from 'react-redux'; 
import uiConfig from '../../config/uiConfig';

const MainContent = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    minHeight: "100vh",
    backgroundColor: theme.pageBg,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {easing: theme.transitions.easing.sharp,duration: theme.transitions.duration.leavingScreen,}),
    marginLeft: `-${uiConfig.drawerWidth}px`, ...(open && {transition: theme.transitions.create('margin', {easing: theme.transitions.easing.easeOut,duration: theme.transitions.duration.enteringScreen,}),
                                     marginLeft: 0,}),
  }),
);

function MainPageArea (props) {
  const drawerOpen = useSelector(state => state.uiSettings.drawerOpen);
  return (
    <MainContent open={drawerOpen}>
          <Toolbar sx={{minHeight:uiConfig.toolBarHeight, height:uiConfig.toolBarHeight}}/>        
          <Box id="mainpage" sx={{width:"100%", flexDirection:"column", flexGrow:1}}>        
              {props.children}
          </Box >        
    </MainContent>             
  )
}

export default MainPageArea;
