// material-ui
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Alert = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
          stroke="currentColor" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" className="feathericon"> 
          <path d="m10.489 5.0201-7.2612 12.122a1.7146 1.7146 0 0 0 1.466 2.5718h14.522a1.7146 1.7146 0 0 0 1.466-2.5718l-7.2612-12.122a1.7146 1.7146 0 0 0-2.9319 0z"/>
          <line x1="11.955" x2="11.955" y1="9.4265" y2="12.856"/>
          <line x1="11.955" x2="11.964" y1="16.285" y2="16.285"/>     
      </svg>  
    </SvgIcon>
  );
};

export default Alert;
