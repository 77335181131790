import { useState } from 'react';
import { Grid, Stack, Tab, Box} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import MainPageHeader from "../../components/Page/MainPageHeader";
import ContentCard from '../../components/Page/ContentCard';
import AddressData from '../../components/Common/AddressData';

function TenantData(props)
{
    const [activeTab, setActiveTab] = useState("1");
    const handleTabChange = (event, newTab) => { setActiveTab(newTab) };

    return(
        <Stack direction="column" spacing={1}>               
            <MainPageHeader title="ADD TENANT"></MainPageHeader>                  
            <Grid>
                <ContentCard width="50%">                
                    <TabContext value={activeTab}>            
                        <TabList variant="scrollable" scrollButtons="auto" onChange={handleTabChange} aria-label="Tenant addresses">
                            <Tab label="Mailing Address" value="1"/>
                            <Tab label="Billing Address" value="2"/>
                            <Tab label="Temporary Address" value="3"/>
                            <Tab label="HolidayAddress" value="4"/>                        
                        </TabList>
                        <TabPanel value="1">{<AddressData/>}</TabPanel>
                        <TabPanel value="2">{<AddressData/>}</TabPanel>
                        <TabPanel value="3">{<AddressData/>}</TabPanel>                         
                        <TabPanel value="4">{<AddressData/>}</TabPanel>                                             
                    </TabContext>
                </ContentCard>                   
            </Grid>            
        </Stack>
    )    
}

export default TenantData; 

/*
                    <TabList onChange={handleTabChange} aria-label="Tenant addresses">
                        <Tab label="Tab one" value="1"/>
                        <Tab label="Tab two" value="2"/>
                        <Tab label="Tab three" value="3"/>
                    </TabList>
                    <TabPanel value="1">{<AddressData/>}</TabPanel>
                    <TabPanel value="2">{<AddressData/>}</TabPanel>
                    <TabPanel value="3">{<AddressData/>}</TabPanel>        
      */
