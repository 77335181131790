import { useTheme } from '@emotion/react';
import { Box, Typography} from '@mui/material';
import { DataGridPro, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport} from '@mui/x-data-grid-pro';



function DataTable(props) {
  const theme = useTheme();

  function CustomToolbar() {
    return (
      <Box my={1}>        
        <GridToolbarContainer >
            <Typography variant="h6" color="primary">{props.title}</Typography>            
            <Box sx={{ flexGrow: 1 }} />            
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector/>
            <GridToolbarExport slotProps={{ tooltip: { title: 'Export data' }}}
            />
        </GridToolbarContainer>
      </Box>
    );
  }

  return (
    <Box px={3} py={4} minWidth={0} maxHeight={"100%"} sx={{flex: 1, '& .MuiDataGrid-columnHeader': { fontWeight:'bold', backgroundColor: theme.tableHeader, color: theme.tableHeaderText}}}>
      <DataGridPro
        columns={props.columns}
        autoHeight={props.autoHeight ? props.autoHeight: true}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 },},}}
        pagination={true}
        pageSizeOptions={props.pageSizeOptions ? props.pageSizeOptions : [5, 10, 25]}
        {...props}
        slots={{toolbar: CustomToolbar}}        
        sx={{border: 0}}
        checkboxSelection
      />
    </Box>
  );
};

export default DataTable;