import MainPageArea from "../../components/Page/MainPageArea";

function PageNotFound()
{
    return (
    <MainPageArea>
        <h1>Page not found</h1>;
    </MainPageArea>
    )
}

export default PageNotFound;