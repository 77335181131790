import {configureStore} from '@reduxjs/toolkit';
import userReducer from './user';
import uiReducer from './ui';

export const store = configureStore({
    reducer: {
        userSettings: userReducer,
        uiSettings: uiReducer
    }
});
