import MainPageArea from "../../components/Page/MainPageArea";
import MainPageHeader from "../../components/Page/MainPageHeader";

function Home()
{
    return (
        <MainPageArea>
            <MainPageHeader title="HOME"></MainPageHeader>
        </MainPageArea>
    )
    
}

export default Home;