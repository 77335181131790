import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
    name:  'uiSettings',
    initialState: { theme: "default",                    
                    drawerOpen: true                                 
                  },
    reducers: {
        setUiAttr: (state, action) => { state[action.payload.attribute] = action.payload.value; },
        activeItem: (state, action) => { state.openItem = action.payload.openItem; },
        activeComponent: (state, action) => { state.openComponent = action.payload.openComponent;},
        openDrawer: (state, action) => { state.drawerOpen = action.payload.drawerOpen; },
        openComponentDrawer: (state, action) =>{ state.componentDrawerOpen = action.payload.componentDrawerOpen; }        
    }
});

export default uiSlice.reducer;
export const { setUiAttr, activeItem, activeComponent, openDrawer, openComponentDrawer }  = uiSlice.actions;