import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name:  'userSettings',
    initialState: { uuid: null, 
                    givenName: "",
                    familyName: "",
                    emails: [],
                    roles: {},  
                    tenant: null,
                    role: null,
                    subscriptionType: null,
                    theme: "default",                    
                    accessToken: null,
                    accessTokenExpiry: null,
                    refreshToken: null                                        
                  },
    reducers: {
        setUserAttr: (state, action) => { state[action.payload.attribute] = action.payload.value; },
    }
});

export default userSlice.reducer;
export const setUserAttr = userSlice.actions.setUserAttr;