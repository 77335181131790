import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import "dayjs/locale/en-au";
import { appRoutes } from './routes/routes';
import { store } from './store/redux/store';
import './css/AeroSuiteMain.css';


const app_router = createBrowserRouter(appRoutes)

function AeroSuiteMain() {
  LicenseInfo.setLicenseKey('e167a31eb3d7d95d2c55aa8c828d08daTz04ODIxOSxFPTE3NDQ0MTkxMjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-au'>
          <RouterProvider router={app_router} />
      </LocalizationProvider>
    </Provider>
  );
}

export default AeroSuiteMain;
