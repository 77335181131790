import { Autocomplete} from '@mui/material';

function StyledAutoComplete(props) {
  return (
    <Autocomplete color="primary"  size="small" {...props} >{props.children}</Autocomplete>
  )
}

export default StyledAutoComplete

