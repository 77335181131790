// material-ui
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const AddCloud = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="feathericon">
          <path d="m14.85 13.807h-0.9875a6.2698 6.2698 0 1 0-6.066 7.8373h7.0535a3.9186 3.9186 0 0 0 0-7.8373z"/>
          <line x1="19.195" x2="19.195" y1="2.2996" y2="8.2996"/>
          <line x1="22.195" x2="16.195" y1="5.2996" y2="5.2996"/>
        </svg>
    </SvgIcon>
  );
};

export default AddCloud;
