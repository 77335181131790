import React from "react";

// Importing different pages
import AdminDashboard from "../layouts/AdminDashboard";
import Home from "../pages/Home/Home";
import Flights from "../pages/Flight/Flights";
import Aircrafts from "../pages/Aircraft/Aircrafts";
import Tenants from "../pages/SysAdmin/Tenants";
import BrowseTenants from "../pages/SysAdmin/TenantBrowse";
import TenantData from "../pages/SysAdmin/TenantData";
import PageNotFound from "../pages/Error/PageNotFound";
import Test from "../pages/Test/Test";

// Import help functions
import ProtectedRoute from "../util/ProtectedRoute";

// Define routes and loaders
const appRoutes = [
   { path: '/',
     element: <ProtectedRoute><AdminDashboard/></ProtectedRoute>, 
     children: [   
         {index: true, path:'', element: <Home/>},
         {path: 'flight', element: <Flights/>},
         {path: 'aircraft', element: <Aircrafts/>},        
         {path: 'tenant', element: <Tenants/>,
          children:[{path: 'browse', element: <BrowseTenants/>}, 
                    {path: 'add', element: <TenantData/>}
                  ]},                      
         {path: 'test', element: <Test/>},                 
         {path: '*', element: <PageNotFound/>}
        ]
   }
]

export { appRoutes };