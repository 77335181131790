import { useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import ContentCard from '../Page/ContentCard';


function AddressData(props) {
  const [address, setAddress] = useState({addr1: "", addr2: ""});  

  return (
        <Grid container spacing={2}>
            <Grid item xs={12}> <TextField id="outlined-basic" label="Street Address 1" size="small" variant="outlined" sx={{ width:"100%", "& .MuiInputBase-root": {minWidth:'100%'}}}/></Grid>
            <Grid item xs={12}> <TextField id="outlined-basic" label="Street Address 2" size="small" variant="outlined" sx={{ width:"100%", "& .MuiInputBase-root": {minWidth:'100%'}}}/></Grid>
            <Grid item xs={12} lg={8}>  <TextField id="outlined-basic" label="Suburb" size="small" variant="outlined" sx={{ width:"100%", "& .MuiInputBase-root": {minWidth:'100%'}}}/></Grid>
            <Grid item xs={12} lg={4}>  <TextField id="outlined-basic" label="Postcode" size="small" variant="outlined" sx={{ width:"100%", "& .MuiInputBase-root": {minWidth:'100%'}}}/></Grid>            
            <Grid item xs={4} lg={4}>  <TextField id="outlined-basic" label="Country" size="small" variant="outlined" sx={{ width:"100%", "& .MuiInputBase-root": {minWidth:'100%'}}}/></Grid>                        
        </Grid>    
  )
}

export default AddressData