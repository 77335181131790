import {Box, Stack, Typography } from '@mui/material';

function MainPageHeader(props) {
  return (
    <Box id="header" sx={{paddingTop:"12px", paddingBottom:"12px",width:"100%"}}>
      <Stack alignItems="left" direction='row' spacing={2}>
        <Box flexGrow={1}>
          <Typography color="primary" variant="h4">{props.title}</Typography>
        </Box>
        <Stack alignItems="right" direction='row' spacing={2}> 
          {props.children}
        </Stack>                         
      </Stack> 
    </Box>  
  )
}

export default MainPageHeader