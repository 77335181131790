import { useTheme } from '@emotion/react';
import { Box, Paper, useMediaQuery} from '@mui/material';
import uiConfig from '../../config/uiConfig';

function MainContentArea (props) {
  const theme = useTheme();
  return (
      <Box flexGrow={1}>
        <Paper sx={{width:"100%", height:"100%",  padding: uiConfig.pageContentPadding }}>
          {props.children}
        </Paper>
      </Box>        
  )
}

export default MainContentArea;
