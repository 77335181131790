import { useMsalAuthentication, AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";
import background from "../assets/img/loginbackground.jpg";
import styles from "../css/ProtectedRoute.module.css";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = (props) => {
  const {result} = useMsalAuthentication("redirect", loginRequest);    
  
  useEffect(() => {
    if (result) {
      let tokenClaims = jwtDecode(result.accessToken);
      console.log(tokenClaims);
    }
  }, [result]);
  
  return (
    <>
      <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate><div className={styles.background} style={{ backgroundImage:`url(${background})`}}></div></UnauthenticatedTemplate>
    </>
  );
}
export default ProtectedRoute;