import { useState } from 'react';
import { Grid, Stack, TextField} from "@mui/material";
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import { useTheme } from "@emotion/react";
import SearchIcon from '@mui/icons-material/Search';
import MainPageArea from "../../components/Page/MainPageArea";
import MainPageHeader from "../../components/Page/MainPageHeader";
import MainContentArea from "../../components/Page/MainContentArea";
import MainContentCard from "../../components/Page/MainContentCard";
import MainActionIcon from "../../components/Page/MainActionIcon";
import StyledAutocomplete from '../../components/Common/StyledAutocomplete';
import StyledButton from '../../components/Common/StyledButton';
import DataTable from "../../components/Common/DataTable";
import Takeoffs from "../../components/icons/Takeoffs";
import EditTakeoff from "../../components/icons/Takeoff-edit";
import AddTakeoff from "../../components/icons/Takeoff-add";
import DeleteTakeoff from "../../components/icons/Takeoff-delete";
import uiConfig from "../../config/uiConfig";


function Flights()
{
    const today = dayjs(new Date());
    const theme = useTheme();
    const [value, setValue] = useState([today.subtract(1, 'month'), today]);

    const tempData = ['MEL','SYD','BNE','LHR'];
    const tempData2 = ['VH-VYK','VH-VYL','VH-VZA','VH-OQA'];

    const columns = [
        //{ field: 'id', headerName: 'ID', width: 70 },
        { field: 'aircraft', headerName: 'AIRCRAFT', type:'string', flex: 1 },
        { field: 'atot', headerName: 'ATOT', type:'dateTime', flex: 1.5 },
        { field: 'aldt', headerName: 'ALDT', type:'dateTime',flex: 1.5 },    
        { field: 'adep', headerName: 'ADEP', type:'string', flex: 1 },        
        { field: 'ades', headerName: 'ADES', type:'string', flex: 1 },      
        { field: 'pic', headerName: 'PILOT IN COMMAND', type:'string', flex: 2.5 },                
        { field: 'fuel', headerName: 'FUEL', type:'number', flex: 0.5 },                        
      ];
      
      const rows = [
        { id: 1, aircraft: 'VH-VYL', atot: new Date('2024-04-01 13:14:15'), aldt: new Date('2024-04-01 14:14:15'), adep:'BNE', ades:'SYD', pic:'Travolta, John', fuel: 2500},
        { id: 2, aircraft: 'VH-OGA', atot: new Date('2024-04-07 15:55:27'), aldt: new Date('2024-04-08 06:45:17'), adep:'SYD', ades:'LHR', pic:'Ford, Harrison', fuel: 1700},
        { id: 3, aircraft: 'VH-VZA', atot: new Date('2024-03-28 06:07:13'), aldt: new Date('2024-03-28 08:07:13'), adep:'SYD', ades:'MEL', pic:'Cruise, Tom', fuel:100},
        { id: 4, aircraft: 'VH-VYL', atot: new Date('2024-04-01 17:34:58'), aldt: new Date('2024-04-01 18:39:10'), adep:'SYD', ades:'BNE', pic:'Travolta, John', fuel: 2200},
        { id: 5, aircraft: 'VH-VZA', atot: new Date('2024-03-29 11:24:15'), aldt: new Date('2024-03-29 13:48:47'), adep:'MEL', ades:'SYD', pic:'Ford, Harrison',fuel:115},
        { id: 6, aircraft: 'VH-VYK', atot: new Date('2024-01-01 09:24:15'), aldt: new Date('2024-01-01 15:24:15'), adep:'SYD', ades:'PER', pic:'Cruise, Tom', fuel:666},
      ];


    return (
        <MainPageArea>        
            <MainContentArea>              
                <Stack direction="column" spacing={1}>
                    <MainPageHeader title="FLIGHTS">
                        <MainActionIcon icon={<Takeoffs sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Browse flights"/> 
                        <MainActionIcon icon={<AddTakeoff sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Add flight"/> 
                        <MainActionIcon icon={<EditTakeoff sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Edit flight"/>                                 
                        <MainActionIcon icon={<DeleteTakeoff sx={{fontSize: uiConfig.pageActionIconSize}}/>} tooltip="Delete flight"/>                 
                    </MainPageHeader>   
                    <MainContentCard>
                        <Grid container px={3} py={3} spacing={3}>
                            <Grid item xs={12} md={6} lg={4}><DateRangePicker  value={value} onChange={(newValue) => { setValue(newValue);}} 
                                                                              localeText={{ start: 'START', end: 'END'}} 
                                                                              slotProps={{textField:{size:"small"}, width:"100%"}}/></Grid>
                            <Grid item xs={12} md={4} lg={2}><StyledAutocomplete options={tempData2} renderInput={(params) => <TextField label="AIRCRAFT" {...params}/>}></StyledAutocomplete></Grid>                                                                                                 
                            <Grid item xs={6} md={3} lg={2}><StyledAutocomplete options={tempData} renderInput={(params) => <TextField label="ADEP" {...params}/>}></StyledAutocomplete></Grid>
                            <Grid item xs={6} md={3} lg={2}><StyledAutocomplete options={tempData} renderInput={(params) => <TextField label="ADES" {...params}/>}></StyledAutocomplete></Grid>                    
                            <Grid item xs={2} md={2} lg={2}><StyledButton variant="contained" color="primary" size="medium" startIcon={<SearchIcon/>}>SEARCH</StyledButton></Grid>                                     
                        </Grid>                                      
                    </MainContentCard>                                          
                    <MainContentCard>
                        <DataTable rows={rows} columns={columns}/>
                    </MainContentCard>
                </Stack>                
            </MainContentArea>
        </MainPageArea>        
    )
}

export default Flights;