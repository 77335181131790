// material-ui
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Aircrafts = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
          stroke="currentColor" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" className="feathericon"> 
          <path d="m8.4064 8.6636c0.10819-0.032541 0.2257-0.045947 0.35253-0.040207 0.30403 0.019144 0.57077 0.14358 0.79833 0.37714s0.3488 0.50732 0.36745 0.81937c0.0076 0.13019-0.0056 0.24888-0.03918 0.36182-0.01492 0.05169-0.03357 0.10146-0.0541 0.15124l1.7514 0.41926 4.1819-4.296c0.3171-0.30822 0.69574-0.46138 1.1378-0.46328 0.45326 0 0.83936 0.16847 1.1639 0.49966 0.32269 0.33119 0.48496 0.72747 0.48683 1.1907 0 0.45372-0.15109 0.84427-0.45139 1.1697l-4.1894 4.2921h0.0056l0.40474 1.8053h0.0056c0.04663-0.0268 0.09513-0.04594 0.14735-0.06124 0.10819-0.03255 0.2257-0.04594 0.35253-0.04021 0.30403 0.01914 0.57077 0.14358 0.79833 0.37714s0.3488 0.50732 0.36745 0.81937c0.0076 0.13019-0.0037 0.25079-0.03544 0.36374-0.04476 0.15506-0.12497 0.2929-0.24434 0.41543l-0.01865 0.01914c-0.26113 0.26419-0.52041 0.51115-0.77782 0.7447h0.0019c0.37491 1.6636 0.74983 3.3254 1.1248 4.9851l-1.6974 0.93801-2.0667-4.0605c-0.42155 0.21058-0.83935 0.37331-1.2516 0.49008 0.14548-0.53986 0.36745-1.0893 0.66404-1.6464l-0.95878-1.88c-1.0483 1.076-2.0965 2.1498-3.1448 3.2258l0.3003 2.0427-0.00562 0.0058-1.4903 0.8251-1.4456-2.5557h-0.00189l-2.492-1.4855 0.80393-1.5315 0.00559-0.0058 1.9903 0.30822 3.1392-3.2315-1.8205-0.9763c-0.54466 0.30247-1.0819 0.52837-1.6079 0.6777 0.11565-0.42309 0.27605-0.85001 0.48123-1.2845l-3.9636-2.1212 0.91397-1.744c1.6209 0.38671 3.2437 0.77533 4.8646 1.1621 0.2257-0.26419 0.46631-0.52837 0.72186-0.79448 0.00753-0.00575 0.013062-0.013405 0.020522-0.019144 0.11751-0.12253 0.25368-0.20484 0.40476-0.25079zm0.37491 1.4186 1.0483 0.25079m4.7396 5.6954-0.24435-1.0797m-5.9334-0.93618c0.70507-0.72556 1.4101-1.453 2.117-2.1767m-4.6445 7.1752c0.39917-0.40968 0.79833-0.82128 1.1975-1.231m3.6708-1.363h0.0019c0.70321-0.72748 1.4082-1.453 2.1134-2.1786"/>
          <path d="m19.804 5.5271c0.3171-0.30822 0.69574-0.46137 1.1378-0.46328 0.45326 0 0.83936 0.16847 1.1639 0.49966 0.32269 0.33119 0.48496 0.72747 0.48683 1.1907 0 0.45372-0.15109 0.84427-0.45139 1.1697l-4.1894 4.2921h0.0056l0.40476 1.8053h0.0056c0.04663-0.0268 0.09513-0.04594 0.14735-0.06124 0.10819-0.03255 0.2257-0.04594 0.35253-0.04021 0.30403 0.01914 0.57077 0.14358 0.79833 0.37714s0.3488 0.50732 0.36746 0.81937c0.0076 0.13019-0.0037 0.25079-0.03544 0.36374-0.04476 0.15506-0.12497 0.2929-0.24434 0.41543l-0.01865 0.01914c-0.26113 0.26419-0.52041 0.51115-0.77782 0.7447h0.0019c0.37491 1.6636 0.74983 3.3254 1.1248 4.9851l-1.6974 0.93801-0.64031-1.2489m0.86241-6.2344-0.24435-1.0797"/>
      </svg>  
    </SvgIcon>
  );
};

export default Aircrafts;
